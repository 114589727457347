import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { round } from 'lodash';
import moment from 'moment';

export const getAircraftStatusData = (status) => {
	switch (status) {
	case 'in_flight':
		return {
			text: 'IN FLIGHT',
			color: '#87d068'
		};
	case 'in_maintenance':
		return {
			text: 'IN MAINTENANCE',
			color: '#2db7f5'
		};
	case 'needs_maintenance':
		return {
			text: 'NEEDS MAINTENANCE',
			color: '#f5222d'
		};
	case 'out_of_order':
		return {
			text: 'OUT OF ORDER',
			color: null
		};
	case 'inactive':
		return {
			text: 'INACTIVE',
			color: null
		};
	default:
		return {};
	}
};

export const getTrackingParameters = userSelf => get(userSelf, 'active_organization.tracking_parameters') || [];
export const isEnabledParameter = (enabledParameters = [], key) => enabledParameters.includes(key);

export const getMinutesFromHours = (hours) => {
	if (!hours) {
		return 0;
	}

	hours = Math.abs(hours);

	const pointValue = (hours - Math.trunc(hours));

	return Number(((Number(pointValue) * 60)).toFixed());
};

export const displayHoursWithMinutes = (hours) => {
	if (!isNumber(hours)) {
		return 0;
	}

	if (isNumber(hours) && !Number(hours)) {
		return '0:00';
	}

	return `${Math.trunc(hours)}:${`${getMinutesFromHours(hours)}`.padStart(2, '0')}`;
};


export const getRemaining = (current = 0, next = 0, field) => {
	const value = next - current;

	if (!next) {
		return '-';
	}

	if (field.type === 'hours') {
		return displayHoursWithMinutes(value);
	}

	return round(Number(next - current), field.precision);
};

export const getRemainingDays = (next) => {
	if (!next) {
		return null;
	}

	return moment(next).startOf('day').diff(moment().startOf('day'), 'days');
};


export const getAircraftNextMaintenanceColor = (aircraft, key, displayWithVariation) => {
	if (key === 'date') {
		let value = get(aircraft.stats, 'next_date') ? moment(get(aircraft.stats, 'next_date')) : null;
		let variation = get(aircraft.stats, 'days_variation');

		if (displayWithVariation) {
			value = get(aircraft.stats, 'next_with_variation_date') ? moment(get(aircraft.stats, 'next_with_variation_date')) : null;
			variation = (get(aircraft.stats, 'days_with_variation_variation') || 0) * 2;
		}

		if (!value) {
			return null;
		}

		const isAwl = displayWithVariation ? true : get(aircraft.stats, 'date_awl');

		const current = moment();
		const nearDueStart = value.clone().subtract(Number(variation) || 0, 'days');
		const pastDueStart = isAwl ? value : value.clone().add(Number(variation || 0), 'days');

		if (current.isSameOrAfter(pastDueStart)) {
			return '#f5222d';
		}

		if (current.isSameOrAfter(nearDueStart)) {
			return '#faad14';
		}

		return null;
	}

	let value = get(aircraft.stats, `next_${key}`);
	let variation = get(aircraft.stats, `${key}_variation`);

	if (displayWithVariation) {
		value = get(aircraft.stats, `next_with_variation_${key}`);
		variation = (get(aircraft.stats, `${key}_with_variation_variation`) || 0) * 2;
	}

	if (!value) {
		return null;
	}

	const current = aircraft[key] || 0;

	if (!current) {
		return null;
	}

	const isAwl = displayWithVariation ? true : get(aircraft.stats, `${key}_awl`);

	const nearDueStart = Number(value || 0) - Number(variation || 0);
	const pastDueStart = isAwl ? value : value + Number(variation || 0);

	if (pastDueStart <= current) {
		return '#f5222d';
	}

	if (nearDueStart <= current) {
		return '#faad14';
	}

	return null;
};

export const isUUID = (str) => {
	const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
	return uuidRegex.test(str);
};

export const convertHoursAndMinutes = (hours, minutes) => {
	if (!Number(minutes) && !Number(hours)) {
		return 0;
	}

	const isNegative = hours < 0;

	hours = Math.abs(Math.trunc(hours) || 0);
	minutes = Number((Number(minutes) / 60).toFixed(4)) || 0;

	const total = hours + minutes;

	return isNegative ? -(total) : total;
};

export const getCustomFieldValues = (values, customFields = []) => {
	const data = {};
	customFields.forEach((field) => {
		if (field.id in values) {
			data[field.id] = values[field.id];

			delete values[field.id];
		}
	});

	return data;
};

export const getStatusColor = (status) => {
	switch (status) {
	case 'not_due':
		return '#87d068';
	case 'near_due':
		return '#faad14';
	case 'past_due':
		return '#f5222d';
	case 'projection':
		return '#0800ff';
	case 'inactive':
		return '#777777';
	case 'open':
		return 'red';
	default:
		return '#87d068';
	}
};

export const getStatusData = (status) => {
	switch (status) {
	case 'active':
		return {
			text: 'ACTIVE',
			color: getStatusColor(status)
		};
	case 'not_due':
		return {
			text: 'NOT DUE',
			color: getStatusColor(status)
		};
	case 'near_due':
		return {
			text: 'NEAR DUE',
			color: getStatusColor(status)
		};
	case 'past_due':
		return {
			text: 'PAST DUE',
			color: getStatusColor(status)
		};
	case 'inactive':
		return {
			text: 'INACTIVE',
			color: getStatusColor(status)
		};
	case 'open':
		return {
			text: 'OPEN',
			color: getStatusColor(status)
		};
	case 'closed':
		return {
			text: 'CLOSED',
			color: 'default'
		};
	default:
		return {
			text: 'NOT DUE',
			color: getStatusColor(status)
		};
	}
};
