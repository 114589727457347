import React, { useState, useContext, useEffect, Fragment } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { CloseOutline } from 'antd-mobile-icons';
import { List, Popup, NavBar, Calendar, TextArea, Button, Toast, Input, Stepper, DatePickerView, PickerView, Form } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import { SQL_DATE_FORMAT } from '../utils/constants';
import { convertHoursAndMinutes, displayHoursWithMinutes, getMinutesFromHours } from '../utils/helpers';

const InputContent = styled.div`
	background: #fff;
	margin-top: 12px;
	padding: 12px;
`;

const TimepickerInfo = styled.div`
	flex: 1;
	text-align: center;
	color: var(--adm-color-weak);
`;

const TimepickerInfoWrapper = styled.div`
	display: flex;
`;

const Save = styled(Button)`
	font-size: 17px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const AutocompleteInput = styled(Input)`
	padding-bottom: 12px;
	margin-bottom: 12px;
	border-bottom: 1px solid var(--adm-color-light);
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

const AutocompleteWrapper = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function InputComponent(props) {
	const {
		title,
		dataKey,
		secondKey,
		initialValue,
		type,
		defaultValue,
		placeholder,
		color,
		min,
		max,
		digits = 0,
		onSave,
		options = [],
		disabled
	} = props;
	const globalState = useContext(store).state;
	const [saving, setSaving] = useState(false);
	const [value, setValue] = useState(initialValue || defaultValue);
	const [visible, setVisible] = useState(false);
	const { userSelf } = globalState;

	const [form] = Form.useForm();

	function onClose() {
		setVisible(false);
	}

	useEffect(() => {
		form.resetFields();
		setValue(initialValue || defaultValue);
	}, [initialValue, visible]);

	function getInputContent() {
		switch (type) {
		case 'date':
			return <Calendar
				selectionMode='single'
				defaultValue={new Date()}
				value={value}
				onChange={val => setValue(val)}
			/>;
		case 'datetime':
			return <Fragment>
				<TimepickerInfoWrapper>
					<TimepickerInfo>
						Year
					</TimepickerInfo>
					<TimepickerInfo>
						Month
					</TimepickerInfo>
					<TimepickerInfo>
						Day
					</TimepickerInfo>
					<TimepickerInfo>
						Hour
					</TimepickerInfo>
					<TimepickerInfo>
						Minute
					</TimepickerInfo>
				</TimepickerInfoWrapper>
				<DatePickerView
					mode="datetime"
					precision="minute"
					title="Takeoff time"
					min={min}
					max={max}
					defaultValue={initialValue || new Date()}
					value={value || new Date()}
					onChange={setValue}
				/>
			</Fragment>;
		case 'stepper':
			return <Stepper
				style={{ width: '100%' }}
				digits={digits}
				min={min}
				defaultValue={initialValue}
				value={value || 0}
				onChange={setValue}
			/>;
		case 'select':
			return <PickerView
				columns={[options]}
				value={value}
				defaultValue={initialValue}
				onChange={setValue}
			/>;
		case 'hours':
			return <Form form={form} layout='horizontal'>
				<Form.Item label='Hours' initialValue={Math.round(value) || 0} name='hours'>
					<Input
						min={0}
						type='number'
						clearable
					/>
				</Form.Item>
				<Form.Item initialValue={getMinutesFromHours(value) || 0} label='Minutes' name='minutes'>
					<Input
						min={0}
						max={59}
						type='number'
						clearable />
				</Form.Item>
			</Form>;
		case 'autocomplete':
			return <AutocompleteWrapper>
				<AutocompleteInput
					placeholder={placeholder}
					value={value.label || ''}
					disabled={value.key}
					onChange={val => setValue({
						...value,
						label: val
					})}
				/>
				<PickerView
					columns={[options]}
					value={[value.key || null]}
					onChange={val => setValue({
						label: val[0] ? get(options.find(o => o.value === val[0]), 'label') : '',
						key: val[0]
					})}
				/>
			</AutocompleteWrapper>;
		case 'text':
			return <TextArea onChange={val => setValue(val)} value={value || ''} placeholder={placeholder} rows={5} />;
		case 'number':
			return <Input
				placeholder={placeholder}
				value={value || ''}
				type="number"
				min={0}
				onChange={val => setValue(val)}
			/>;
		default:
			return <Input
				placeholder={placeholder}
				value={value || ''}
				onChange={val => setValue(val)}
			/>;
		}
	}

	async function onSaveClick() {
		setSaving(true);
		let saveValue = value;
		let secondValue;

		switch (type) {
		case 'date':
			saveValue = value ? moment(value).format(SQL_DATE_FORMAT) : null;
			break;
		case 'datetime':
			saveValue = moment(value || new Date());
			secondValue = moment(value || new Date()).format('HH:mm:ss');
			break;
		case 'select':
			[saveValue] = value;
			break;
		case 'autocomplete':
			saveValue = value.key || value.label;
			break;
		case 'hours':
			saveValue = convertHoursAndMinutes(form.getFieldValue('hours'), form.getFieldValue('minutes'));
			break;
		default:
			break;
		}

		const data = {
			[dataKey]: saveValue
		};

		if (secondKey) {
			data[secondKey] = secondValue;
		}

		await onSave(data).catch(() => Toast.show({
			icon: 'fail',
			content: <ToastContent>
				Something went wrong, cannot save.
			</ToastContent>
		}));

		setSaving(false);
		setVisible(false);
	}

	function getInitialValue() {
		switch (type) {
		case 'date':
			return initialValue ? moment(initialValue).format(get(userSelf.settings, 'date_format')) : '-';
		case 'datetime':
			return initialValue ? moment(initialValue).format(`${get(userSelf.settings, 'date_format')} HH:mm`) : '-';
		case 'text':
			return initialValue || '-';
		case 'select':
			return initialValue[0] || '-';
		case 'stepper':
			return initialValue || 0;
		case 'autocomplete':
			return initialValue?.label || '-';
		case 'hours':
			return initialValue ? displayHoursWithMinutes(initialValue) : '-';
		default:
			return initialValue || '-';
		}
	}

	return (
		<Fragment>
			<List.Item
				title={title}
				onClick={() => (disabled ? null : setVisible(true))}
				clickable={!disabled}
			>
				<span style={{ color }}>{getInitialValue()}</span>
			</List.Item>
			<Popup
				destroyOnClose
				position='right'
				visible={visible}
				bodyStyle={{ width: '100%', background: '#f0f2f5' }}
				onClose={onClose}
			>
				<Nav
					backArrow={<CloseOutline />}
					onBack={onClose}
					back={''}
					right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
				>
					{title}
				</Nav>
				<InputContent>
					{getInputContent()}
				</InputContent>
			</Popup>
		</Fragment>
	);
}

export default InputComponent;
