import React, { Fragment, useContext } from 'react';
import moment from 'moment';
import { Ellipsis, List, Skeleton } from 'antd-mobile';
import styled from 'styled-components';
import { get } from 'lodash';
import { store } from '../utils/store';
import { displayHoursWithMinutes, getAircraftNextMaintenanceColor, getRemaining, getRemainingDays, getTrackingParameters, isEnabledParameter } from '../utils/helpers';

const InfoWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const AircraftInfoContent = styled.div`
	margin-top: 12px;
	padding-bottom: 12px;
	.adm-list-item-title {
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;


function AircraftInfo(props) {
	const {
		aircraft,
		loading
	} = props;

	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	const TRACKING_PARAMETERS = getTrackingParameters(userSelf);

	return (<InfoWrapper>
		<AircraftInfoContent>
			{loading ?
				<Fragment>
					<Skeleton.Title animated/>
					<Skeleton.Paragraph lineCount={1} animated/>
				</Fragment> :
				<List header="Next maintenance">
					<List.Item
						title="Date"
						extra={`${getRemainingDays(get(aircraft, 'stats.next_date'))} days left`}
						clickable={false}
						description={<Ellipsis direction='end' rows={1} content={get(aircraft, 'stats.date_name')} />}
					>
						<span style={{ color: getAircraftNextMaintenanceColor(aircraft, 'date') }}>
							{get(aircraft, 'stats.next_date') ? moment(get(aircraft, 'stats.next_date')).format(get(userSelf, 'settings.date_format')) : '-' }
						</span>
					</List.Item>
					{TRACKING_PARAMETERS.map((param) => {
						if (isEnabledParameter(aircraft.enabled_parameters, param.key)) {
							return <List.Item
								key={param.key}
								title={param.name}
								extra={`${getRemaining(aircraft[param.key], get(aircraft, `stats.next_${param.key}`), param)} left`}
								clickable={false}
								description={<Ellipsis direction='end' rows={1} content={get(aircraft, `stats.${param.key}_name`)} />}
							>
								<span style={{ color: getAircraftNextMaintenanceColor(aircraft, param.key) }}>
									{
										param.type === 'hours' ?
											displayHoursWithMinutes(get(aircraft, `stats.next_${param.key}`)) :
											(get(aircraft, `stats.next_${param.key}`) || '-')
									}
								</span>
							</List.Item>;
						}

						return null;
					})}
				</List>}
		</AircraftInfoContent>
	</InfoWrapper>);
}

export default AircraftInfo;
